<template>
	<div class="sell">
		<div class="top">
			<my-nft-list-nav :list="nav_list" :index="nav_index" @click="navTap"/>
		</div>
		<div class="main">
			<div class="left">
				<div class="card-small">
					<img src="@/assets/bg_revoke.svg" alt="">
					<img class="card-img" :src="card_img" alt="">
					<div class="card-content">
						<div class="card-name">{{ card.data.zhHans }}</div>
						<div class="card-info">
							<div class="card-info_item">
								<div class="card-info_left">
									<span></span>
									<span>{{ $t('OWN') }}</span>
								</div>
								<div class="num">{{ card.own }}</div>
							</div>

							<div class="card-info_item">
								<div class="card-info_left">
									<span></span>
									<span>{{ $t('STAKE') }}</span>
								</div>
								<div class="num">{{ card.is_synthetic_card ? card.stake_for_farm : card.stake_for_mine }}</div>
							</div>

							<div class="card-info_item" v-if="!card.is_synthetic_card">
								<div class="card-info_left">
									<span></span>
									<span>{{ $t('MINING POWER') }}</span>
								</div>
								<div class="num">{{ card.data.mining_power }}</div>
							</div>

							<div class="card-info_item" v-if="card.is_synthetic_card">
								<div class="card-info_left">
									<span></span>
									<span>{{ $t('GOD STRENGTH') }}</span>
								</div>
								<div class="num">{{ card.data.god_strength }}</div>
							</div>

							<div class="card-info_item" v-if="card.is_synthetic_card">
								<div class="card-info_left">
									<span></span>
									<span>{{ $t('BONUS PROPORTION') }}</span>
								</div>
								<div class="num">{{ card.data.bonus_proportion }}%</div>
							</div>
						</div>
					</div>
				</div>

				<div class="form-item">
					<div class="form-label">{{ $t('CHOOSE THE CURRENCY') }}</div>
					<div class="form-input_box">
						<img class="form-logo" src="@/assets/logo.png" alt="">
						<input class="form-input" type="text" value="AMT" disabled>
					</div>
				</div>

				<div class="form-item">
					<div class="form-label">{{ $t('BUY OUT PRICE') }}</div>
					<div class="form-input_box">
						<img class="form-logo" src="@/assets/logo.png" alt="">
						<input class="form-input" type="number" placeholder="0" v-model="buy_price">
					</div>
				</div>

				<div class="form-item">
					<div class="form-label">{{ $t('START PRICE') }}</div>
					<div class="form-input_box">
						<img class="form-logo" src="@/assets/logo.png" alt="">
						<input class="form-input" type="number" placeholder="0" v-model="start_price">
					</div>
				</div>

				<div class="form-item">
					<div class="form-label">{{ $t('PERIOD') }}</div>
					<div class="form-input_box">
						<img class="form-logo" src="@/assets/logo.png" alt="">
						<input class="form-input" type="text" value="7DAY" disabled>
					</div>
				</div>

				<div class="form-item">
					<div class="form-label">{{ $t('ORDER FEE') }}</div>
					<div class="form-input_box">
						<img class="form-logo" src="@/assets/logo.png" alt="">
						<input class="form-input" type="text" value="5%" disabled>
					</div>
				</div>

				<div class="form-balance">
					<div class="form-balance_label">{{ $t('YOUR BALANCE') }}</div>
					<div class="form-balance_content">
						<img class="form-logo" src="@/assets/logo.png" alt="">
						<span>{{ Number(amt_balance).toFixed(4) }}</span>
					</div>
				</div>

				<div :class="{'text-center': is_mobile}">
					<v-btn
						:style="[is_mobile ? mobile_btn_styles : pc_btn_styles]"
						color="#EB6930"
						large
						outlined
						rounded
						:loading="auction_loading"
						@click="auctionCard"
					>{{ $t('AUCTION') }}</v-btn>
				</div>
			</div>
			<div class="card-preview_box" v-show="!is_mobile">
				<div class="card-preview">
					<img class="card-preview_bg" src="@/assets/bg_revoke.svg" alt="">
					<img class="card-preview_img" :src="card_img" alt="">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { SessionStorage } from 'storage-manager-js'
import { createAuction, getAMTAmt } from '@/utils/cardFactory'
import { getCardImgUrl } from '@/utils/tools'
import { mapState } from 'vuex'
import eventBus from '@/utils/eventBus'
import MyNftListNav from '@/views/Game/MyNFT/MyNftListNav'

export default {
	name: 'Auction',
	components: { MyNftListNav },
	data () {
		return {
			card: null,
			amt_balance: 0,
			start_price: '',
			buy_price: '',
			auction_loading: false,
			nav_list: '',
			nav_index: 0,
			pc_btn_styles: {
				height: '52px !important',
				width: '263px',
				marginTop: '60px',
			},
			mobile_btn_styles: {
				height: '34px !important',
				width: '188px',
				marginTop: '44px',
			},
		}
	},
	computed: {
		...mapState(['ADDRESS']),
		card_img () {
			return getCardImgUrl(this.card.key)
		},
		is_mobile () {
			return this.$vuetify.breakpoint.mobile
		},
	},
	created () {
		const index = this.$route.query.index
		if (index) {
			this.nav_index = Number(index)
		}
		this.nav_list = SessionStorage.get('NFT_NAV_LIST')
		this.card = SessionStorage.get('TRANSACTION_CARD')
		if (this.ADDRESS) {
			this._amtBalance()
		}
		eventBus.$on('connect-event', (res) => {
			this._amtBalance()
		})
	},
	methods: {
		navTap ({ index }) {
			this.$router.push({
				path: '/app/Game/MyNFT',
				query: { index },
			})
		},
		async auctionCard () {
			if (this.start_price === '' || this.buy_price === '') return
			this.auction_loading = true
			const tokenId = this.card.ids[0]
			try {
				await createAuction(this.ADDRESS, tokenId, this.start_price, this.buy_price)
				this.auction_loading = false
				this.$router.push('/app/AuctionList')
			} catch (err) {
				console.log(err)
				this.auction_loading = false
			}
		},
		async _amtBalance () {
			this.amt_balance = await getAMTAmt(this.ADDRESS)
		},
	},
}
</script>

<style scoped>

</style>
